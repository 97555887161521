import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import AssistantPhotoOutlinedIcon from "@mui/icons-material/AssistantPhotoOutlined";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import BrunchDiningOutlinedIcon from "@mui/icons-material/BrunchDiningOutlined";
import CastleIcon from "@mui/icons-material/Castle";
import CastleOutlinedIcon from "@mui/icons-material/CastleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import ForestIcon from "@mui/icons-material/Forest";
import ForestOutlinedIcon from "@mui/icons-material/ForestOutlined";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import LocalPlayOutlinedIcon from "@mui/icons-material/LocalPlayOutlined";
import MuseumIcon from "@mui/icons-material/Museum";
import MuseumOutlinedIcon from "@mui/icons-material/MuseumOutlined";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import NightlifeOutlinedIcon from "@mui/icons-material/NightlifeOutlined";
import SailingIcon from "@mui/icons-material/Sailing";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import SpaIcon from "@mui/icons-material/Spa";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import TheaterComedyOutlinedIcon from "@mui/icons-material/TheaterComedyOutlined";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { useRef } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ValueOf } from "ts-essentials";

import { QuestionnaireInterestUnselectEvent } from "../../../custom-events";
import { QuestionnaireInterestSelectEvent } from "../../../custom-events";
import { useNextTranslation } from "../../../hooks/useNextTranslation";
import { QuestionnaireV2StepProps } from "../../QuestionnaireV2/QuestionnaireV2Provider";
import { Typography } from "../../Typography";
import { RecommendationsQuestionnaireFormInputs } from "../RecommendationsQuestionnaire";
import { RecommendationsQuestionnaireStepContent } from "../RecommendationsQuestionnaireContent";
import { RecommendationsQuestionnaireFooter, NextButton } from "../RecommendationsQuestionnaireFooter";
import { RecommendationsQuestionnaireHeader } from "../RecommendationsQuestionnaireHeader";

const CONSUMER_TRIP_INTEREST_TAG = {
    FOOD_AND_DRINK: "2ab0e582-f674-11ee-8fae-06d804c228db",
    ON_THE_WATER: "2ab2ebda-f674-11ee-8fae-06d804c228db",
    HISTORY_AND_CULTURE: "2ab3576e-f674-11ee-8fae-06d804c228db",
    GALLERIES_AND_MUSEUMS: "2ab36285-f674-11ee-8fae-06d804c228db",
    GUIDED_TOURS: "2ab36ba7-f674-11ee-8fae-06d804c228db",
    COOKING_AND_CRAFTS: "2ab374e0-f674-11ee-8fae-06d804c228db",
    ADVENTURE_AND_OUTDOORS: "2ab37efa-f674-11ee-8fae-06d804c228db",
    EXCLUSIVE_OR_VIP: "2ab388ab-f674-11ee-8fae-06d804c228db",
    MUST_DO: "2ab3923c-f674-11ee-8fae-06d804c228db",
    NIGHTLIFE_AND_ENTERTAINMENT: "2ab39bfe-f674-11ee-8fae-06d804c228db",
    THEATRE: "2ab3a60e-f674-11ee-8fae-06d804c228db",
    GOOD_FOR_SOLO_TRAVELERS: "2ab3b04b-f674-11ee-8fae-06d804c228db",
    RELAXED: "2ab3bb93-f674-11ee-8fae-06d804c228db",
} as const;

export type ConsumerTripInterestTagId = ValueOf<typeof CONSUMER_TRIP_INTEREST_TAG>;

export const consumerTripInterestTagIdList = Object.values(CONSUMER_TRIP_INTEREST_TAG);

export const isConsumerTripInterestTagId = (value: string): value is ConsumerTripInterestTagId => {
    return consumerTripInterestTagIdList.includes(value as ConsumerTripInterestTagId);
};

type InterestItem = {
    id: ConsumerTripInterestTagId;
    systemTagId: string;
    label: string;
    Icon: React.ComponentType<SvgIconProps>;
    IconSelected: React.ComponentType<SvgIconProps>;
};

const useRecommendationsInterestItemList = (): InterestItem[] => {
    const [t] = useNextTranslation("questionnaire");

    const interests: Record<ConsumerTripInterestTagId, Omit<InterestItem, "id">> = {
        [CONSUMER_TRIP_INTEREST_TAG.FOOD_AND_DRINK]: {
            label: t("recommendations.interests.food.label"),
            Icon: BrunchDiningOutlinedIcon,
            IconSelected: BrunchDiningIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.FOOD_AND_DRINK,
        },
        [CONSUMER_TRIP_INTEREST_TAG.ON_THE_WATER]: {
            label: t("recommendations.interests.water.label"),
            Icon: SailingOutlinedIcon,
            IconSelected: SailingIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.ON_THE_WATER,
        },
        [CONSUMER_TRIP_INTEREST_TAG.HISTORY_AND_CULTURE]: {
            label: t("recommendations.interests.history.label"),
            Icon: CastleOutlinedIcon,
            IconSelected: CastleIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.HISTORY_AND_CULTURE,
        },
        [CONSUMER_TRIP_INTEREST_TAG.GALLERIES_AND_MUSEUMS]: {
            label: t("recommendations.interests.galleries.label"),
            Icon: MuseumOutlinedIcon,
            IconSelected: MuseumIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.GALLERIES_AND_MUSEUMS,
        },
        [CONSUMER_TRIP_INTEREST_TAG.GUIDED_TOURS]: {
            label: t("recommendations.interests.guidedTours.label"),
            Icon: AssistantPhotoOutlinedIcon,
            IconSelected: AssistantPhotoIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.GUIDED_TOURS,
        },
        [CONSUMER_TRIP_INTEREST_TAG.COOKING_AND_CRAFTS]: {
            label: t("recommendations.interests.cooking.label"),
            Icon: ColorLensOutlinedIcon,
            IconSelected: ColorLensIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.COOKING_AND_CRAFTS,
        },
        [CONSUMER_TRIP_INTEREST_TAG.ADVENTURE_AND_OUTDOORS]: {
            label: t("recommendations.interests.adventure.label"),
            Icon: ForestOutlinedIcon,
            IconSelected: ForestIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.ADVENTURE_AND_OUTDOORS,
        },
        [CONSUMER_TRIP_INTEREST_TAG.EXCLUSIVE_OR_VIP]: {
            label: t("recommendations.interests.exclusive.label"),
            Icon: LocalPlayOutlinedIcon,
            IconSelected: LocalPlayIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.EXCLUSIVE_OR_VIP,
        },
        [CONSUMER_TRIP_INTEREST_TAG.MUST_DO]: {
            label: t("recommendations.interests.mustDo.label"),
            Icon: BeenhereOutlinedIcon,
            IconSelected: BeenhereIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.MUST_DO,
        },
        [CONSUMER_TRIP_INTEREST_TAG.NIGHTLIFE_AND_ENTERTAINMENT]: {
            label: t("recommendations.interests.nightlife.label"),
            Icon: NightlifeOutlinedIcon,
            IconSelected: NightlifeIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.NIGHTLIFE_AND_ENTERTAINMENT,
        },
        [CONSUMER_TRIP_INTEREST_TAG.THEATRE]: {
            label: t("recommendations.interests.theatre.label"),
            Icon: TheaterComedyOutlinedIcon,
            IconSelected: TheaterComedyIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.THEATRE,
        },
        [CONSUMER_TRIP_INTEREST_TAG.GOOD_FOR_SOLO_TRAVELERS]: {
            label: t("recommendations.interests.soloTraveler.label"),
            Icon: EmojiPeopleOutlinedIcon,
            IconSelected: EmojiPeopleIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.GOOD_FOR_SOLO_TRAVELERS,
        },
        [CONSUMER_TRIP_INTEREST_TAG.RELAXED]: {
            label: t("recommendations.interests.relaxed.label"),
            Icon: SpaOutlinedIcon,
            IconSelected: SpaIcon,
            systemTagId: CONSUMER_TRIP_INTEREST_TAG.RELAXED,
        },
    };

    return Object.entries(interests).map(([id, props]) => ({ id: id as ConsumerTripInterestTagId, ...props }));
};

export function RecommendationsInterestsStep({ onNext, onClose }: QuestionnaireV2StepProps) {
    const [t] = useNextTranslation("questionnaire");
    const { control, getValues } = useFormContext<RecommendationsQuestionnaireFormInputs>();
    const { append, remove } = useFieldArray({ control, name: "interestList" });
    const firstName = getValues("consumerFirstName");
    const interestSystemTagIdList = getValues("interestList");
    const interests = useRecommendationsInterestItemList();
    const isSubmitEnabled = interestSystemTagIdList.length > 0;
    const title = firstName
        ? t("recommendations.interests.title.label", { firstName })
        : t("recommendations.interests.titleNoFirstName.label");
    const contentRef = useRef<HTMLDivElement>(null);

    const indexOf = (id: ConsumerTripInterestTagId) => {
        return interestSystemTagIdList.findIndex((field) => field.id === id);
    };
    const isSelected = (id: ConsumerTripInterestTagId) => {
        return indexOf(id) > -1;
    };
    const onItemToggle = (id: ConsumerTripInterestTagId) => {
        const itemPosition = interests.findIndex((field) => field.id === id);
        if (isSelected(id)) {
            remove(indexOf(id));
            contentRef.current?.dispatchEvent(new QuestionnaireInterestUnselectEvent({ itemId: id, itemPosition }));
        } else {
            append({ id, value: id });
            contentRef.current?.dispatchEvent(new QuestionnaireInterestSelectEvent({ itemId: id, itemPosition }));
        }
    };

    return (
        <>
            <RecommendationsQuestionnaireHeader onClose={onClose} />
            <RecommendationsQuestionnaireStepContent ref={contentRef}>
                <Typography variant="title" size="large" color="subtle" align="center" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="body" size="large" color="subtle" align="center">
                    {t("recommendations.selectApplicable.label")}
                </Typography>
                <InterestsItemsContainer>
                    {interests.map((interest) => {
                        const { id } = interest;
                        return (
                            <InterestItem
                                key={interest.id}
                                {...interest}
                                isSelected={isSelected(id)}
                                onClick={onItemToggle}
                            />
                        );
                    })}
                </InterestsItemsContainer>
            </RecommendationsQuestionnaireStepContent>
            <RecommendationsQuestionnaireFooter>
                <NextButton onClick={() => onNext()} disabled={!isSubmitEnabled} />
            </RecommendationsQuestionnaireFooter>
        </>
    );
}

type InterestItemProps = InterestItem & {
    isSelected: boolean;
    onClick: (id: ConsumerTripInterestTagId) => void;
};

const InterestItem = (props: InterestItemProps) => {
    const { id, label, Icon, isSelected, onClick } = props;
    const IconComponent = isSelected ? props.IconSelected : Icon;

    return (
        <InterestsItem data-selected={isSelected} key={id} onClick={() => onClick(id)}>
            {isSelected && <SelectedIcon data-check-icon="true" />}
            <IconComponent data-activity-icon="true" color="primary" />
            <InterestLabel variant="body" size="medium" color="subtle">
                {label}
            </InterestLabel>
        </InterestsItem>
    );
};

const InterestsItemsContainer = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: theme.spacing(1.25),
    [theme.breakpoints.up("sm")]: {
        maxWidth: 600,
    },
}));

const InterestsItem = styled("div")(({ theme }) => ({
    position: "relative",
    width: 104,
    minHeight: 104,
    padding: theme.spacing(0, 0.25, 0, 0.25),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: theme.roundness,
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.border.subtle}`,
    cursor: "pointer",
    boxShadow: theme.shadows[2],
    WebkitTapHighlightColor: "transparent",
    "& [data-activity-icon='true']": {
        height: 40,
        width: 40,
    },
    "&[data-selected='true']": {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        boxShadow: "none",
        color: theme.palette.primary.main,
    },
    ":hover, &[data-selected='true']": {
        backgroundColor: `${theme.palette.primary.main}0A`,
    },
}));

const InterestLabel = styled(Typography)(({ theme }) => ({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    textAlign: "center",
    "&&&": {
        letterSpacing: 0.1,
        lineHeight: "110%",
        fontSize: 13,
        marginTop: theme.spacing(0.5),
        fontWeight: 500,
    },
}));

const SelectedIcon = styled(CheckCircleIcon)(({ theme }) => ({
    position: "absolute",
    top: -8,
    right: -8,
    color: theme.palette.primary.main,
    width: 24,
    backgroundColor: theme.palette.light.main,
    borderRadius: "50%",
}));
