import { Skeleton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Typography } from "../Typography";
import { ConsumerTripStoriesProductCardSkeleton } from "./ConsumerTripStoriesProductCardSkeleton";

export function ConsumerTripStoriesSkeleton() {
    return (
        <Stack>
            <TextSkeleton variant="text" width={200}>
                <Typography size="large" variant="body">
                    Loading
                </Typography>
            </TextSkeleton>
            <TextSkeleton variant="text" width={300}>
                <Typography size="large" variant="headline">
                    Loading
                </Typography>
            </TextSkeleton>
            <ConsumerTripStoriesProductsSkeleton />
        </Stack>
    );
}

export function ConsumerTripStoriesProductsSkeleton() {
    return (
        <ProductsContainer>
            <ConsumerTripStoriesProductCardSkeleton />
            <ConsumerTripStoriesProductCardSkeleton />
            <ConsumerTripStoriesProductCardSkeleton />
        </ProductsContainer>
    );
}

const ProductsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(),
    "&::-webkit-scrollbar": {
        display: "none",
    },
}));

const TextSkeleton = styled(Skeleton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[700],
}));
