import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { useCallback, useRef, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripProductList, ConsumerTripProductsQueryResult } from "../../apiHooks/useConsumerTripProductsQuery";
import { StoryDisplayEvent, StoryProductsScrollEvent } from "../../custom-events";
import { StoryProductClickEvent } from "../../custom-events";
import { useNextTranslation } from "../../hooks";
import { ProductItem } from "../../types/ProductItem";
import { ConsumerTripPersonalizationShelf } from "../../utils/storiesHelper";
import { HeroImageConsumerTrip } from "../HbmlComponents/HbmlConsumerTripNode";
import { MainColumnWrapper } from "../MainColumnWrapper";
import { Stories } from "../Stories/Stories";
import { ConsumerTripStoriesSkeleton } from "./ConsumerTripStoriesSkeleton";
import { ConsumerTripStoryContent } from "./ConsumerTripStoryContent";
import { ConsumerTripStoryPersonal } from "./ConsumerTripStoryPersonal";
import { TypeIconMap, getTitleAndSubtitleForShelves } from "./common/useConsumerTripHeaderData";

type ConsumerTripStoriesContainerProps = {
    rules: ConsumerTripPersonalizationShelf[];
    products: ConsumerTripProductsQueryResult;
    consumerTrip: Pick<HeroImageConsumerTrip, "consumerTripId" | "consumer">;
    placeId: string;
    consumerTripSystemTagIdList: string[];
};

const ANIMATION_DURATION_IN_MS = 600;

type StoryMetaDataBase = {
    storyTitle: string;
    shelfType: ConsumerTripContentItemProductListType;
    IconComponent: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
        muiName: string;
    };
};

type StoryMetaDataDefault = StoryMetaDataBase & {
    title: string;
    loading: boolean;
    subtitle: string;
};

type StoryMetaDataCustomComponent = StoryMetaDataBase & {
    Component: JSX.Element;
};

export type StoryMetaData = StoryMetaDataDefault | StoryMetaDataCustomComponent;

export function ConsumerTripStoriesContainer(props: ConsumerTripStoriesContainerProps) {
    const { rules, products, consumerTrip, placeId, consumerTripSystemTagIdList } = props;
    const [t] = useNextTranslation("hbml");
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const [selectedShelfType, setSelectedShelfType] = useState<ConsumerTripContentItemProductListType>(
        Object.keys(products)[0] as ConsumerTripContentItemProductListType
    );
    const [autoPlay, setAutoPlay] = useState(true);
    const [scrolledStoriesList, setScrolledStoriesList] = useState<ConsumerTripContentItemProductListType[]>([]);
    const displayedStoriesListRef = useRef<ConsumerTripContentItemProductListType[]>([]);

    const stopAutoPlay = () => setAutoPlay(false);

    const onProductClick = (item: ProductItem, position: number) => {
        nodeRef.current?.dispatchEvent(
            new StoryProductClickEvent({
                item,
                position,
                shelfType: selectedShelfType,
            })
        );
    };

    const handleScroll = () => {
        if (!scrolledStoriesList.includes(selectedShelfType)) {
            setScrolledStoriesList((prev) => [...prev, selectedShelfType]);
            nodeRef.current?.dispatchEvent(new StoryProductsScrollEvent(selectedShelfType));
        }
    };

    const onStoryProductsDisplay = useCallback(
        (productList: ConsumerTripProductList, shelfType: ConsumerTripContentItemProductListType) => {
            if (productList.length > 1 && !displayedStoriesListRef.current.includes(shelfType)) {
                displayedStoriesListRef.current = [...displayedStoriesListRef.current, shelfType];
                nodeRef.current?.dispatchEvent(
                    new StoryDisplayEvent({
                        itemList: productList.map(({ id, name }) => ({ id: id, name: name ?? "" })),
                        shelfType: shelfType,
                    })
                );
            }
        },
        []
    );

    const storyMetaDataList: StoryMetaData[] = [
        ...rules.map(({ type, typeParams }) => ({
            ...getTitleAndSubtitleForShelves({ type, typeParams, t }),
            storyTitle: t(`consumerTrip.shelf.${type.toLowerCase()}.storyTitle`, { params: typeParams?.join(", ") }),
            IconComponent: TypeIconMap[type],
            shelfType: type,
            loading: products[type]?.loading ?? true,
        })),
        {
            storyTitle: t("consumerTrip.shelf.personal.storyTitle"),
            IconComponent: TypeIconMap.PERSONAL,
            shelfType: ConsumerTripContentItemProductListType.Personal,
            Component: (
                <ConsumerTripStoryPersonal
                    consumerTrip={consumerTrip}
                    placeId={placeId}
                    stopAutoPlay={stopAutoPlay}
                    consumerTripSystemTagIdList={consumerTripSystemTagIdList}
                    onProductClick={onProductClick}
                    onScroll={handleScroll}
                    onStoryProductsDisplay={onStoryProductsDisplay}
                />
            ),
        },
    ];

    const handleStoryChange = (shelfType: ConsumerTripContentItemProductListType) => {
        setSelectedShelfType(shelfType);
    };

    const currentProductListToDisplay = products[selectedShelfType]!;
    const currentStoryMetaData = storyMetaDataList.find((x) => x.shelfType === selectedShelfType);
    const withSkeletonLoading =
        currentProductListToDisplay.loading ||
        !currentStoryMetaData ||
        (!("Component" in currentStoryMetaData) && currentProductListToDisplay.data.length === 0);

    return (
        <Container>
            <MainColumnWrapper>
                <Stories
                    storyList={storyMetaDataList.slice(0, 5)}
                    onStoryChange={handleStoryChange}
                    autoPlay={autoPlay}
                    onAutoPlayChange={setAutoPlay}
                />
                <ContentContainer>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={selectedShelfType}
                            nodeRef={nodeRef}
                            classNames="fade"
                            timeout={ANIMATION_DURATION_IN_MS}
                        >
                            <div ref={nodeRef}>
                                {withSkeletonLoading ? (
                                    <ConsumerTripStoriesSkeleton />
                                ) : "Component" in currentStoryMetaData ? (
                                    currentStoryMetaData.Component
                                ) : (
                                    <ConsumerTripStoryContent
                                        stopAutoPlay={stopAutoPlay}
                                        subtitle={currentStoryMetaData.subtitle}
                                        title={currentStoryMetaData.title}
                                        productList={currentProductListToDisplay.data}
                                        onProductClick={onProductClick}
                                        onScroll={handleScroll}
                                        onStoryProductsDisplay={onStoryProductsDisplay}
                                        shelfType={selectedShelfType}
                                    />
                                )}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </ContentContainer>
            </MainColumnWrapper>
        </Container>
    );
}

const Container = styled("div")(({ theme }) => ({
    background: theme.palette.dark.main,
}));

const ContentContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& .fade-enter": {
        opacity: 0,
        transform: "translateX(100%)",
    },
    "& .fade-exit": {
        opacity: 1,
        transform: "translateX(0%)",
    },
    "& .fade-enter-done": {
        opacity: 1,
    },
    "& .fade-enter-active": {
        opacity: 1,
        transform: "translateX(0%)",
        transition: `opacity ${ANIMATION_DURATION_IN_MS}ms, transform ${ANIMATION_DURATION_IN_MS}ms`,
    },
    "& .fade-exit-active": {
        opacity: 0,
        transform: "translateX(-100%)",
        transition: `opacity ${ANIMATION_DURATION_IN_MS}ms, transform ${ANIMATION_DURATION_IN_MS}ms`,
    },
}));
