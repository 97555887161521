import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ConsumerTripStoriesProductCard } from "./ConsumerTripStoriesProductCard";

export function ConsumerTripStoriesProductCardSkeleton() {
    return (
        <ProductCardSkeleton variant="rounded">
            <ConsumerTripStoriesProductCard name={null} price={null} id={""} slug={null} />
        </ProductCardSkeleton>
    );
}

const ProductCardSkeleton = styled(Skeleton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[700],
    borderRadius: theme.roundness,
}));
