import { faCloud } from "@fortawesome/pro-regular-svg-icons/faCloud";
import { faCloudBolt } from "@fortawesome/pro-regular-svg-icons/faCloudBolt";
import { faCloudBoltMoon } from "@fortawesome/pro-regular-svg-icons/faCloudBoltMoon";
import { faCloudBoltSun } from "@fortawesome/pro-regular-svg-icons/faCloudBoltSun";
import { faCloudHail } from "@fortawesome/pro-regular-svg-icons/faCloudHail";
import { faCloudMoon } from "@fortawesome/pro-regular-svg-icons/faCloudMoon";
import { faCloudMoonRain } from "@fortawesome/pro-regular-svg-icons/faCloudMoonRain";
import { faCloudRain } from "@fortawesome/pro-regular-svg-icons/faCloudRain";
import { faCloudSleet } from "@fortawesome/pro-regular-svg-icons/faCloudSleet";
import { faCloudSnow } from "@fortawesome/pro-regular-svg-icons/faCloudSnow";
import { faCloudSun } from "@fortawesome/pro-regular-svg-icons/faCloudSun";
import { faCloudSunRain } from "@fortawesome/pro-regular-svg-icons/faCloudSunRain";
import { faFog } from "@fortawesome/pro-regular-svg-icons/faFog";
import { faMoon } from "@fortawesome/pro-regular-svg-icons/faMoon";
import { faSnowflake } from "@fortawesome/pro-regular-svg-icons/faSnowflake";
import { faSnowflakeDroplets } from "@fortawesome/pro-regular-svg-icons/faSnowflakeDroplets";
import { faSun } from "@fortawesome/pro-regular-svg-icons/faSun";
import { faWind } from "@fortawesome/pro-regular-svg-icons/faWind";
import { styled } from "@mui/material/styles";

import { dayjs } from "@holibob-packages/dayjs";

import { FontAwesomeIconRaw, HeroImagesDescription, RawIconDefinition } from ".";
import { ConsumerTripWeatherForecastFragment } from "../gql-request";
import { useNextTranslation } from "../hooks";

export type WeatherIconsProps = {
    forecast: ConsumerTripWeatherForecastFragment[] | null;
};
export function WeatherIcons({ forecast = [] }: WeatherIconsProps) {
    const [t] = useNextTranslation("hbml");
    if (!forecast?.length) return null;
    return (
        <Container>
            {forecast.map((data) => {
                const iconData = data.type ? icons[data.type] : null;

                if (!iconData) return null;

                const [width, height, , , path] = iconData.icon;
                const iconDefinition: RawIconDefinition = {
                    iconName: iconData.iconName,
                    prefix: iconData.prefix,
                    width,
                    height,
                    path: Array.isArray(path) && path.length > 0 ? path.join(",") : (path as string),
                };
                return (
                    <IconContainer key={data.date}>
                        <FontAwesomeIconRaw definition={iconDefinition} />
                        <HeroImagesDescription>{t(days[dayjs(data.date).day()])}</HeroImagesDescription>
                    </IconContainer>
                );
            })}
        </Container>
    );
}

const days = [
    "consumerTrip.weatherForecast.sun",
    "consumerTrip.weatherForecast.mon",
    "consumerTrip.weatherForecast.tue",
    "consumerTrip.weatherForecast.wed",
    "consumerTrip.weatherForecast.thu",
    "consumerTrip.weatherForecast.fri",
    "consumerTrip.weatherForecast.sat",
];

type IconDefinition = {
    icon: any;
    iconName: string;
    prefix: string;
};

const icons: Record<string, IconDefinition> = {
    CLEAR_DAY: faSun,
    CLEAR_NIGHT: faMoon,
    CLOUDY: faCloud,
    FOG: faFog,
    HAIL: faCloudHail,
    PARTLY_CLOUDY_DAY: faCloudSun,
    PARTLY_CLOUDY_NIGHT: faCloudMoon,
    RAIN_SNOW_SHOWERS_DAY: faSnowflakeDroplets,
    RAIN_SNOW_SHOWERS_NIGHT: faSnowflakeDroplets,
    RAIN_SNOW: faSnowflakeDroplets,
    RAIN: faCloudRain,
    SHOWERS_DAY: faCloudSunRain,
    SHOWERS_NIGHT: faCloudMoonRain,
    SLEET: faCloudSleet,
    SNOW_SHOWERS_DAY: faCloudSnow,
    SNOW_SHOWERS_NIGHT: faCloudSnow,
    SNOW: faSnowflake,
    THUNDER_RAIN: faCloudBolt,
    THUNDER_SHOWERS_DAY: faCloudBoltSun,
    THUNDER_SHOWERS_NIGHT: faCloudBoltMoon,
    THUNDER: faCloudBolt,
    WIND: faWind,
};

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    width: "fit-content",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(),
}));

const IconContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.light.main,
    gap: theme.spacing(0.5),
    opacity: 1,
}));
