// eslint-disable-next-line no-restricted-imports
import type { SvgIconComponent } from "@mui/icons-material";
import DiamondIcon from "@mui/icons-material/Diamond";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
import History from "@mui/icons-material/History";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Work from "@mui/icons-material/Work";
import { TFunction } from "i18next";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripContentItemProductListItemFragment } from "../../../apiHooks/graphql";
import { useNextTranslation } from "../../../hooks";

export type ConsumerTripHeaderData = {
    title: string;
    subTitle?: string;
    Icon: SvgIconComponent;
};

export const TypeIconMap: { [x in ConsumerTripContentItemProductListType]: SvgIconComponent } = {
    LIKELY_TO_SELL_OUT: RunningWithErrorsOutlinedIcon,
    LONG_HAUL: ExploreOutlinedIcon,
    NEARBY: RoomOutlinedIcon,
    PAX_4_OR_MORE: Groups2Icon,
    RAINING_DURING_STAY: ThunderstormOutlinedIcon,
    RAINING_NEXT_FEW_DAYS: ThunderstormOutlinedIcon,
    SHORT_TRIP: TimerOutlinedIcon,
    LONG_TRIP: DiamondOutlinedIcon,
    LIKED_SYSTEM_TAGS: FavoriteBorderOutlinedIcon,
    FAMILY_FRIENDLY: FamilyRestroomIcon,
    HIDDEN_GEMS: DiamondIcon,
    TOP_ATTRACTIONS: FavoriteBorderOutlinedIcon,
    RECENTLY_VIEWED: History,
    PERSONAL: FavoriteIcon,
    BUSINESS: Work,
};

export const getTitleAndSubtitleForShelves = ({
    type,
    typeParams,
    t,
}: ConsumerTripHeaderDataProps & { t: TFunction }) => {
    let title = t(`consumerTrip.shelf.${type.toLowerCase()}.title`, { params: typeParams?.join(", ") });
    const subtitle = t(`consumerTrip.shelf.${type.toLowerCase()}.subTitle`, {
        params: typeParams?.join(", "),
        defaultValue: "",
    });

    if (type === "NEARBY") {
        const params = typeParams?.join(",");
        title = params?.length
            ? t(`consumerTrip.shelf.${type.toLowerCase()}.title`, { params })
            : t(`consumerTrip.shelf.${type.toLowerCase()}.titleWithoutParams`);
    }

    return { title, subtitle };
};

export type ConsumerTripHeaderDataProps = {
    type: ConsumerTripContentItemProductListType;
    typeParams: ConsumerTripContentItemProductListItemFragment["typeParams"];
};

export function useConsumerTripHeaderData({ type, typeParams }: ConsumerTripHeaderDataProps): ConsumerTripHeaderData {
    const [t] = useNextTranslation("hbml");
    const { title, subtitle } = getTitleAndSubtitleForShelves({ type, typeParams, t });

    return {
        title,
        subTitle: subtitle,
        Icon: TypeIconMap[type],
    };
}
