import { styled } from "@mui/material/styles";
import { Ref } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ConsumerTripContentItemProductListItemFragment } from "../../apiHooks/graphql";
import { useConsumerTripProductsQuery } from "../../apiHooks/useConsumerTripProductsQuery";
import { computeFinalConsumerTripShelfList } from "../../utils/storiesHelper";
import { PageLayout } from "../HbmlComponents/common/PageLayout";
import { useSectionPadding } from "../HbmlComponents/hooks/useSectionPadding";
import { ConsumerTripContainerContentProps } from "./ConsumerTripContainer";
import { ConsumerTripShelfContainer } from "./ConsumerTripShelfContainer";
import { ConsumerTripShelfRecentlyViewed } from "./ConsumerTripShelfRecentlyViewed";
import { ConsumerTripStoriesContainer } from "./ConsumerTripStoriesContainer";

const ConsumerTripContainerWrapper = styled(PageLayout)(({ theme }) => ({
    "&:has([data-ref='main-column-wrapper']:empty)": {
        display: "none",
    },
    "& [data-ref='main-column-wrapper']:empty": {
        display: "none",
    },
    "& [data-ref='divider']:last-child": {
        display: "none",
    },
    "& [data-ref='main-column-wrapper']": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(4),
        [theme.breakpoints.up("md")]: {
            gap: theme.spacing(6),
        },
    },
}));

type ConsumerTripStoriesAndShelvesProps = Omit<ConsumerTripContainerContentProps, "onProductClick"> & {
    sliderRules: ConsumerTripContentItemProductListItemFragment[];
    placeId: string;
    consumerTripShelfRef?: Ref<HTMLDivElement> | undefined;
    consumerTripSystemTagIdList: string[];
};

export function ConsumerTripStoriesAndShelves({
    sliderRules,
    placeId,
    consumerTrip,
    consumerTripSystemTagIdList,
    ...props
}: ConsumerTripStoriesAndShelvesProps) {
    const dataForShelfList = useConsumerTripProductsQuery({ personalizationShelfList: sliderRules });
    const sectionPadding = useSectionPadding();
    const shelfTypeListWithData = new Set<ConsumerTripContentItemProductListType>();
    for (const [shelfType, data] of Object.entries(dataForShelfList)) {
        if (data.loading || data.data.length > 0) {
            shelfTypeListWithData.add(shelfType as ConsumerTripContentItemProductListType);
        }
    }
    const finalShelfList = Array.from(
        computeFinalConsumerTripShelfList({
            shelfList: sliderRules,
            shelfTypeListWithData,
            placeId: placeId,
        })
    );
    const storyShelfList = finalShelfList.slice(0, 4);
    const restShelfList = finalShelfList.slice(4);

    return (
        <>
            {
                <ConsumerTripStoriesContainer
                    rules={storyShelfList}
                    consumerTrip={consumerTrip}
                    consumerTripSystemTagIdList={consumerTripSystemTagIdList}
                    placeId={placeId}
                    products={{
                        ...dataForShelfList,
                        PERSONAL: {
                            data: [],
                            loading: false,
                        },
                    }}
                />
            }
            <ConsumerTripContainerWrapper style={{ ...sectionPadding }}>
                {!!restShelfList.length && (
                    <ConsumerTripShelfContainer
                        rules={restShelfList}
                        products={dataForShelfList}
                        consumerTrip={consumerTrip}
                        {...props}
                    />
                )}
                <ConsumerTripShelfRecentlyViewed {...props} consumerTripId={consumerTrip.consumerTripId} />
            </ConsumerTripContainerWrapper>
        </>
    );
}
