import { styled } from "@mui/material/styles";
import { useState, useRef } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ResponsiveImage } from "..";
import { AssetUrl } from "../../../../vault/src/AssetUrl";
import {
    useConsumerTripAssociateSystemTagListMutation,
    useConsumerTripRecommendedProductListQuery,
} from "../../apiHooks/graphql";
import {
    QuestionnaireInterestSelectEvent,
    QuestionnaireInterestUnselectEvent,
    QuestionnaireNextClickEvent,
    QuestionnaireBackClickEvent,
    QuestionnaireCloseEvent,
    QuestionnaireSubmitEvent,
    QuestionnaireChangeOfHeartClickEvent,
} from "../../custom-events";
import { QuestionnaireOpenEvent } from "../../custom-events";
import { useCustomDOMEventHandler } from "../../hooks/useCustomDOMEventHandler";
import { useNextTranslation } from "../../hooks/useNextTranslation";
import { Button } from "../Button";
import { HeroImageConsumerTrip } from "../HbmlComponents/HbmlConsumerTripNode";
import {
    RecommendationsQuestionnaire,
    RecommendationsQuestionnaireFormInputs,
} from "../RecommendationsQuestionnaire/RecommendationsQuestionnaire";
import { isConsumerTripInterestTagId } from "../RecommendationsQuestionnaire/steps/RecommendationsInterestsStep";
import { ConsumerTripStoriesProductsSkeleton } from "./ConsumerTripStoriesSkeleton";
import {
    ConsumerTripStoryContent,
    ConsumerTripStoryContentProps,
    StorySubtitle,
    StoryTitle,
} from "./ConsumerTripStoryContent";

const IMAGE = AssetUrl.fromString("asset://staticImage/questionnaire-story-empty-state.png");

type ConsumerTripStoryPersonalProps = {
    placeId: string;
    consumerTrip: Pick<HeroImageConsumerTrip, "consumerTripId" | "consumer">;
    stopAutoPlay: () => void;
    consumerTripSystemTagIdList: string[];
} & Pick<ConsumerTripStoryContentProps, "onProductClick" | "onScroll" | "onStoryProductsDisplay">;

export const ConsumerTripStoryPersonal = (props: ConsumerTripStoryPersonalProps) => {
    const [t] = useNextTranslation("hbml");
    const {
        placeId,
        consumerTrip,
        stopAutoPlay,
        consumerTripSystemTagIdList,
        onProductClick,
        onScroll,
        onStoryProductsDisplay,
    } = props;

    const [open, setOpen] = useState(false);
    const { consumerTripId, consumer } = consumerTrip;
    const consumerTripTagIdList = getConsumerTripInterestTagIdList(consumerTripSystemTagIdList);
    const hasConsumerTripInterestAssociateTags = consumerTripTagIdList.length > 0;
    const contentRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const [consumerTripAssociateSystemTagListMutation] = useConsumerTripAssociateSystemTagListMutation();
    const {
        data,
        loading,
        refetch: refetchRecommendedProductList,
    } = useConsumerTripRecommendedProductListQuery({
        variables: { consumerTripId, placeId },
        notifyOnNetworkStatusChange: true,
    });

    const onOpen = () => {
        stopAutoPlay();
        setOpen(true);
    };

    const onOpenNoInterests = () => {
        onOpen();
        contentRef.current?.dispatchEvent(new QuestionnaireOpenEvent({ questionnaireOpenTrigger: "initialCta" }));
    };

    const onOpenWithInterests = () => {
        onOpen();
        contentRef.current?.dispatchEvent(new QuestionnaireOpenEvent({ questionnaireOpenTrigger: "customize" }));
    };

    useCustomDOMEventHandler(modalRef, (subscription) => {
        subscription.on(QuestionnaireInterestSelectEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireInterestUnselectEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireNextClickEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireBackClickEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireCloseEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireSubmitEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
        subscription.on(QuestionnaireChangeOfHeartClickEvent, (event) => {
            contentRef.current?.dispatchEvent(event.clone());
        });
    });

    const onClose = () => setOpen(false);
    const onSubmit = async (data: RecommendationsQuestionnaireFormInputs) => {
        const nonConsumerTripInterestTagIdList = getConsumerTripNonInterestTagIdList(consumerTripSystemTagIdList);
        const systemTagIdList = data.interestList.map(({ value }) => value).concat(nonConsumerTripInterestTagIdList);

        await consumerTripAssociateSystemTagListMutation({
            variables: { consumerTripId, systemTagIdList },
            update(cache) {
                cache.modify({
                    id: cache.identify({ __typename: "ConsumerTripContent" }),
                    fields: {
                        systemTagIdList() {
                            return systemTagIdList;
                        },
                    },
                });
            },
        });

        void refetchRecommendedProductList();

        onClose();
    };

    const recommendedProductList = data?.consumerTripRecommendedProductList?.nodes ?? [];
    const shelfSubTitle = hasConsumerTripInterestAssociateTags
        ? t("consumerTrip.shelf.personal.withSelection.subTitle")
        : t("consumerTrip.shelf.personal.noSelection.subTitle");
    const shelfTitle = hasConsumerTripInterestAssociateTags
        ? t("consumerTrip.shelf.personal.withSelection.title")
        : t("consumerTrip.shelf.personal.noSelection.title");
    return (
        <div ref={contentRef}>
            <StorySubtitle subtitle={shelfSubTitle} />
            <TitleWrapper>
                <Title title={shelfTitle} />
                {hasConsumerTripInterestAssociateTags && (
                    <CustomiseInterestsButton onClick={onOpenWithInterests} variant="outlined" size="small">
                        {t("consumerTrip.shelf.personal.interestsCta.label")}
                    </CustomiseInterestsButton>
                )}
            </TitleWrapper>
            {loading && hasConsumerTripInterestAssociateTags ? (
                <ConsumerTripStoriesProductsSkeleton />
            ) : hasConsumerTripInterestAssociateTags ? (
                <ConsumerTripStoryContent
                    productList={recommendedProductList}
                    stopAutoPlay={stopAutoPlay}
                    onProductClick={onProductClick}
                    onScroll={onScroll}
                    onStoryProductsDisplay={onStoryProductsDisplay}
                    shelfType={ConsumerTripContentItemProductListType.Personal}
                />
            ) : (
                <ConsumerTripStoryPersonalNoInterests onOpen={onOpenNoInterests} />
            )}
            {open && (
                <RecommendationsQuestionnaire
                    consumerTripId={consumerTripId}
                    consumerFirstName={consumer?.givenName ?? undefined}
                    open={open}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    consumerTripSystemTagIdList={consumerTripTagIdList}
                    ref={modalRef}
                />
            )}
        </div>
    );
};

type ConsumerTripStoryPersonalNoProductsProps = {
    onOpen: () => void;
};

const ConsumerTripStoryPersonalNoInterests = ({ onOpen }: ConsumerTripStoryPersonalNoProductsProps) => {
    const [t] = useNextTranslation("hbml");
    return (
        <NoInterestsContainer>
            <NoInterestsContentContainer>
                <ResponsiveImage src={IMAGE.unwrap()} height={165} width={343} alt={"Questionnaire"} />
                <RoundedButton onClick={onOpen} variant="contained">
                    {t("consumerTrip.shelf.personal.openCta.label")}
                </RoundedButton>
            </NoInterestsContentContainer>
        </NoInterestsContainer>
    );
};

const getConsumerTripInterestTagIdList = (consumerTripSystemTagIdList: string[]): string[] => {
    return consumerTripSystemTagIdList.filter(isConsumerTripInterestTagId);
};

const getConsumerTripNonInterestTagIdList = (consumerTripSystemTagIdList: string[]): string[] => {
    return consumerTripSystemTagIdList.filter((x) => !isConsumerTripInterestTagId(x));
};

const TitleWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(2),
    },
    width: "100%",
    flexWrap: "wrap",
}));

const Title = styled(StoryTitle)(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
        flexGrow: 0,
    },
}));

const RoundedButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.roundness,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const CustomiseInterestsButton = styled(RoundedButton)({
    "&&": {
        alignSelf: "center",
        color: "white",
        background: "rgba(192, 192, 192, 0.35)",
        borderColor: "white",
        borderWidth: 2,
        fontSize: 14,
    },
});

const NoInterestsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
});

const NoInterestsContentContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
});
