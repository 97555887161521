"use client";

import Explore from "@mui/icons-material/Explore";
import { styled, keyframes, useTheme } from "@mui/material/styles";
import { HTMLAttributes } from "react";

import { CSSVariable } from "../../utils/CSSVariable";

const STORY_ICON_SIZE = new CSSVariable("--story-icon-size");
const STORY_ICON_LOADER_CIRCUMFERENCE = new CSSVariable("--story-icon-loader-circumference");
const STORY_ICON_ANIMATION_DURATION = new CSSVariable("--story-icon-animation-duration");
const STORY_ICON_ANIMATION_PLAY_STATE = new CSSVariable("--story-icon-animation-play-state");
const STORY_ICON_COLOR = new CSSVariable("--story-icon-color");

export type StoryProps = {
    size?: number;
    durationInSeconds?: number;
    isActive: boolean;
    isPaused?: boolean;
    isLoading?: boolean;
    reset?: boolean;
    IconComponent?: typeof Explore;
    onFinish?: () => void;
    onStart?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export function StoryButton({
    size = 50,
    durationInSeconds = 10,
    isActive = true,
    isPaused = false,
    isLoading = false,
    reset = false,
    IconComponent = Explore,
    onFinish,
    onStart,
    style,
    ...restProps
}: StoryProps) {
    const circleSize = size / 2;
    const radius = circleSize - 1;
    const circumference = 2 * 3.14 * radius;
    const strokeWidth = 2;
    const theme = useTheme();

    const animationCssVariables = {
        [STORY_ICON_SIZE.toString()]: `${size}px`,
        [STORY_ICON_LOADER_CIRCUMFERENCE.toString()]: circumference,
        [STORY_ICON_ANIMATION_DURATION.toString()]: `${durationInSeconds}s`,
        [STORY_ICON_ANIMATION_PLAY_STATE.toString()]: isPaused ? "paused" : "running",
        [STORY_ICON_COLOR.toString()]: isActive ? theme.palette.common.white : theme.palette.grey[500],
    };

    return (
        <Container style={{ ...style, ...animationCssVariables }} {...restProps}>
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "rotate(-90deg)" }}
            >
                <circle
                    r={radius}
                    cx={circleSize}
                    cy={circleSize}
                    fill="rgb(256,256,256, 0.1)"
                    stroke={isActive ? theme.palette.common.white : theme.palette.grey[500]}
                    strokeWidth={strokeWidth}
                ></circle>
                {isLoading ? (
                    <Loader
                        r={radius - strokeWidth}
                        cx={circleSize}
                        cy={circleSize}
                        fill="none"
                        stroke={theme.palette.common.white}
                        strokeWidth={strokeWidth + 2}
                        strokeDasharray={`${circumference * 0.25}px ${circumference * 0.75}px`}
                        data-is-loading={isLoading}
                    ></Loader>
                ) : (
                    <StoryProgress
                        r={radius - strokeWidth}
                        cx={circleSize}
                        cy={circleSize}
                        stroke={theme.palette.common.white}
                        strokeWidth={strokeWidth + 2}
                        strokeDashoffset={`${circumference}px`}
                        fill="transparent"
                        strokeDasharray={`${circumference}px`}
                        onAnimationEnd={onFinish}
                        onAnimationStart={onStart}
                        data-is-active={isActive}
                        data-reset={reset}
                    ></StoryProgress>
                )}
            </svg>
            <IconContainer>
                <IconComponent />
            </IconContainer>
        </Container>
    );
}

const Container = styled("div")({
    position: "relative",
    width: STORY_ICON_SIZE.reference,
    height: STORY_ICON_SIZE.reference,
});

const IconContainer = styled("div")({
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: STORY_ICON_COLOR.reference,
    width: STORY_ICON_SIZE.reference,
    height: STORY_ICON_SIZE.reference,
});

const progressAnimation = keyframes`
    from {
        stroke-dashoffset: ${STORY_ICON_LOADER_CIRCUMFERENCE.reference};
    }
    to {
        stroke-dashoffset: 0;
    }
`;

const loadingAnimation = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const StoryProgress = styled("circle")({
    "&[data-is-active='true'][data-reset='true']": {
        animationName: "none",
    },
    "&[data-is-active='true']": {
        animationDuration: STORY_ICON_ANIMATION_DURATION.reference,
        animationName: progressAnimation,
        animationIterationCount: 1,
        animationTimingFunction: "linear",
        animationPlayState: STORY_ICON_ANIMATION_PLAY_STATE.reference,
    },
});

const Loader = styled("circle")({
    "&[data-is-loading='true']": {
        animationDuration: "3s",
        animationName: loadingAnimation,
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        transformOrigin: "center center",
    },
});
