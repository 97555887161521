import { ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useNextTranslation } from "../../hooks/useNextTranslation";
import { Button } from "../Button";
import { QuesitonnaireV2Footer } from "../QuestionnaireV2/QuestionnaireV2StepLayout";

export const RecommendationsQuestionnaireFooter = styled(QuesitonnaireV2Footer)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    backgroundColor: "#F5F7F9",
}));

const NextButtonComponent = styled(Button)(({ theme }) => ({
    minWidth: 166,
    "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.light.main,
        opacity: 0.4,
    },
    borderRadius: theme.roundness,
}));

type NextButtonProps = { label?: string } & ButtonProps;
export const NextButton = ({ label, ...rest }: NextButtonProps) => {
    const [t] = useNextTranslation("questionnaire");

    return (
        <NextButtonComponent variant="contained" size="large" disableElevation={false} {...rest}>
            {label ?? t("next.label")}
        </NextButtonComponent>
    );
};

type SkipButtonProps = { label?: string } & ButtonProps;
export const SkipButton = ({ label, ...rest }: SkipButtonProps) => {
    const [t] = useNextTranslation("questionnaire");

    return (
        <Button variant="text" data-color="subtle" disableElevation={false} {...rest}>
            {label ?? t("recommendations.footer.skip.label")}
        </Button>
    );
};
