"use client";

import { styled } from "@mui/material/styles";
import { useControlledState } from "@react-stately/utils";
import { useRef, useState } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { StoryClickEvent } from "../../custom-events";
import { useOnMouseHold } from "../../hooks";
import { StoryMetaData } from "../ConsumerTrip/ConsumerTripStoriesContainer";
import { Typography } from "../Typography";
import { StoryButton } from "./Story";

const PLAY_DURATION_IN_SECONDS = 6;
const ICON_WIDTH = 56;

export type StoriesProps = {
    onStoryChange?: (shelfType: ConsumerTripContentItemProductListType) => void;
    storyList: Array<StoryMetaData>;
    autoPlay: boolean;
    onAutoPlayChange?: (val: boolean) => void;
};

export function Stories({ onStoryChange, storyList, autoPlay: autoPlayProp, onAutoPlayChange }: StoriesProps) {
    const [selectedStory, setSelectedStory] = useState(0);
    const [autoPlay, setAutoPlay] = useControlledState(autoPlayProp, true, (val) => onAutoPlayChange?.(val));
    const [isPaused, setIsPaused] = useState(false);
    const [isHoldActive, handleOnMouseDown, handleOnMouseUp] = useOnMouseHold(
        () => setIsPaused(true),
        () => setIsPaused(false)
    );

    const contentRef = useRef<HTMLDivElement>(null);

    const handleOnClick = (index: number) => {
        const shelfType = storyList[index].shelfType;
        if (!isHoldActive) {
            setSelectedStory(index);
            setAutoPlay(false);
            onStoryChange?.(shelfType);
        }

        contentRef.current?.dispatchEvent(new StoryClickEvent(shelfType));
    };
    const handleOnFinish = () => {
        if (autoPlay) {
            if (selectedStory >= storyList.length - 1) {
                setSelectedStory(0);
                onStoryChange?.(storyList[0].shelfType);
            } else {
                const newStoryIndex = selectedStory + 1;
                onStoryChange?.(storyList[newStoryIndex].shelfType);
                setSelectedStory(newStoryIndex);
            }
        }
    };

    return (
        <Container ref={contentRef}>
            {storyList.map((story, index) => {
                const isLoading = "loading" in story ? story.loading : false;
                return (
                    <StoryContainer key={story.storyTitle}>
                        <StoryButton
                            isActive={index === selectedStory}
                            size={ICON_WIDTH}
                            durationInSeconds={PLAY_DURATION_IN_SECONDS}
                            onFinish={handleOnFinish}
                            onStart={() => {
                                if (autoPlay) {
                                    setSelectedStory(index);
                                    onStoryChange?.(storyList[index].shelfType);
                                }
                            }}
                            onClick={!isLoading ? () => handleOnClick(index) : undefined}
                            isLoading={isLoading}
                            isPaused={isPaused || !autoPlay}
                            onMouseDownCapture={handleOnMouseDown}
                            onMouseUpCapture={handleOnMouseUp}
                            onTouchStart={handleOnMouseDown}
                            onTouchEnd={handleOnMouseUp}
                            reset={!autoPlay}
                            IconComponent={story.IconComponent}
                        />
                        <TitleContainer>
                            <StoryTitle data-is-active={index === selectedStory}>{story.storyTitle}</StoryTitle>
                        </TitleContainer>
                    </StoryContainer>
                );
            })}
        </Container>
    );
}

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(),
}));

const TitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(0.5),
    textAlign: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(),
}));

const StoryTitle = styled(Typography)(({ theme }) => ({
    "&&&": {
        fontSize: 12,
        fontWeight: 500,
    },
    color: theme.palette.grey[500],
    "&[data-is-active='true']": {
        color: theme.palette.common.white,
    },
}));

const StoryContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: ICON_WIDTH,
    cursor: "pointer",
});
